@media only screen and (max-width: 768px) {
  .drop-area {
    width: 7vw !important;
    height: 9vh !important;
    
     left: 1vw !important;
   
  }
  .Collage-CreationFirst-Div{
      width: 50%;
  }
  .Collage-Creationsecond-Div{
      width: 50%;
  }
  .Shopping-Collage-Header p {
      font-size: 12px;
  }
  .Shopping-Collage-Header {
      display: flex;
      justify-content: space-between;
      padding: 0% 4% 2% 2%;
  }
  .Drag-Drop-Button-Div{
      display: flex;
      flex-direction: column;
  }
  .scroll {
      width: 60%;
      overflow-y: scroll;
  }
}


@media (min-width: 1024px) {
 
    .scroll {
       
       
    }


}


.AccessoriesText-width{
  width: 35vw;
}


