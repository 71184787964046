.DashboardLayout-Sider .ant-layout-sider-children {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    margin: 10% 0% 6% 0% !important;

}
.DashboardLayout-Side-Menu{
    font-size: 17px;
    font-weight: 400;
    font-family: 'Outfit', sans-serif;
    background-color: #FBF1E9;
}


.mainContainerDiv {
    margin: 24px 16px;
    min-height: 280px;
    border-radius: 8px;
    overflow-y: scroll; /* Enable vertical scrolling */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

}

.mainContainerDiv::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}



#sidenavbar{
    max-width: 222px !important;
    width: 222px !important;
    flex: 0 0 222px !important;
}

#sidenavbarsmall{
    max-width: 80px !important;
    width: 80px !important;
    flex: 0 0 80px !important;
}