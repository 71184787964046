.notifybody {
  display: flex;
  align-items: center;
  /* gap: 10%; */

  /* width: 71%; */
}
.notify {
  /* display: flex; */
  /* justify-content: space-between; */
  margin: 28px;
  align-items: center;
  /* border: 1px solid; */
  background: white;
  /* box-shadow: #d8d6d6 2px 2px 2px 2px; */
  /* padding: 1% 2%; */
  /* border-radius: 8px; */

  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  width: 87%;
  text-align: center;
}
.notify p,
.notifybody p {
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: #777777;
}
.notifybody p {
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: #404040;
}
.notify .notiTitle {
  /* width: 130px; */
  font-weight: 500;
  font-size: 18px;
  margin: 18px;
  display: flex;
  justify-content: start;
  background-color: #404040;
  width: 50%;
  text-align: center;
  border-radius: 18px;
  color: white;
  padding: 2% 4% 2% 4%;
  display: flex;
  justify-content: center;
}
.notiTitle-Div {
  background-color: antiquewhite;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}

.Notisub-Div {
  padding: 0% 2% 5% 2%;
}

.Notification-Content {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.Notification-Content p {
  font-weight: 600;
  color: black;
  font-size: 18px;
}

@media (max-width: 768px) {
  .notiTitle {
    font-size: 16px; /* Slightly smaller on tablet */
  }
  .Notification-Content-Div {
    padding: 3%;
  }

  .notiTitle {
    font-size: 16px !important;
    width: 58% !important;
  }

  .Notification-Content p {
    font-size: 14px; /* Slightly smaller on tablet */
  }

  .Notification-Content div {
    font-size: 12px; /* Slightly smaller on tablet */
  }
}

@media (max-width: 1024px) {
  .notiTitle {
    font-size: 16px; /* Slightly smaller on tablet */
  }
  .Notification-Content-Div {
    padding: 3%;
  }

  .notiTitle {
    font-size: 16px !important;
    width: 58% !important;
  }

  .Notification-Content p {
    font-size: 15px; /* Slightly smaller on tablet */
  }

  .Notification-Content div {
    font-size: 14px; /* Slightly smaller on tablet */
  }
}

/* Responsive font size for mobile */
@media (max-width: 576px) {
  .notiTitle {
    font-size: 14px; /* Smaller on mobile */
  }

  .Notification-Content p,
  .Notification-Content div {
    font-size: 12px; /* Smaller on mobile */
  }
}

.nousernoti {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
