.question-answer-container {
  border: 1px solid #ccc;
  padding: 16px;

  margin: 20px auto;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.record-gallery{
  display: flex;
    flex-wrap: wrap;
}
.record-gallery .insidecontainer{
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;

}
.question {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.answer img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.answer p {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  margin: 0;
}

@media (max-width: 768px) {
  .question-answer-container {
    padding: 12px;
    max-width: 90%;
  }

  .question {
    font-size: 16px;
  }

  .answer p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .question-answer-container {
    padding: 10px;
    max-width: 100%;
  }

  .question {
    font-size: 14px;
  }

  .answer p {
    font-size: 12px;
  }
}

.Question-Answer-Video {
  width: 100%;
}

@media (max-width: 768px) {
  .question-answer-container {
    padding: 12px;
    max-width: 90%;
  }

  .question {
    font-size: 16px;
  }

  .answer p {
    font-size: 14px;
  }
  .Question-Answer-Video {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .question-answer-container {
    padding: 10px;
    max-width: 100%;
  }

  .question {
    font-size: 14px;
  }

  .answer p {
    font-size: 12px;
  }
}
