.Profile-Content {
  padding: 2% 2% 2% 3%;
}
.Profile-Form-Input {
  border: 1.3px solid #ababab;
  padding: 1.5% 2%;
}
.Profile-Header-Logo {
  gap: 4%;
  padding: 0% 0% 4% 0%;
}
.Profile-Header-Buttons button {
  background-color: #31363f !important;
  color: white;
}
.Profile-Header-Buttons button:hover {
  background-color: white !important;
  color: #31363f;
}
.Profile-FormItem .ant-form-item-label > label {
  font-size: 17px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
}


.loadinginprofile {
  position: fixed;      
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;   
  justify-content: center; 
  background-color: rgba(255, 255, 255, 0.7); 
  z-index: 1000;           
}