.user-wardrobe {
  padding: 20px;
}

.user-wardrobe .wear-type-tabs,
.user-wardrobe .category-tabs {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
}

.user-wardrobe .button {
  padding: 7px;
  border: 1px solid #000;
  cursor: pointer;
  flex: 0 0 auto;
  text-align: center;
  margin-right: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  min-width: 100px;
  border-radius: 8px;
}

.user-wardrobe .button:last-child {
  margin-right: 0;
}

.user-wardrobe .button.selected {
  background-color: #000;
  color: #fff;
}

.user-wardrobe .image-containerWardrobe {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  gap: 10px;
  /* max-height: 402px; */

  padding: 10px;
}

.image-item {
  display: flex;
  justify-content: center;
}

.user-wardrobe .image-item {
  margin: 10px;
  border: 1px solid #ddd;
  padding: 10px;
}

.user-wardrobe .image-item img {
  width: 70%;
}

@media (max-width: 768px) {
  .user-wardrobe .image-containerWardrobe {
    grid-template-columns: repeat(1, 1fr);
  }

  .user-wardrobe .wear-type-tabs,
  .user-wardrobe .category-tabs {
    overflow-x: auto;
  }
}

@media (max-width: 480px) {
  .user-wardrobe .button {
    font-size: 14px;
    padding: 8px;
    min-width: 80px;
  }

  .user-wardrobe .image-containerWardrobe {
    max-height: 250px;
  }
}
