.Shopping-Collage-Header {
  display: flex;
  justify-content: space-between;
  padding: 0% 4% 1% 4%;
}

.Shopping-Collage-Header p {
  font-size: 18px;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}

.Shopping-Collage-Header button {
  /* background-color: rgb(243, 210, 21); */
  font-size: 17px;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}
.Shopping-Collage-Input {
  border: none;
  background-color: rgb(224, 219, 219);
}
.Shopping-Collage-Subcontent {
  gap: 5px;
  background-color: rgb(224, 219, 219);
  padding: 0.8%;
  border-radius: 13px;

}

.Scrapig-Linkoutlined {
  font-size: 20px;
}

.Shopping-Collage-Input:focus,
.Shopping-Collage-Input:hover,
.Shopping-Collage-Input::after {
  border: none;
  background-color: rgb(224, 219, 219);
  /* border: none; */
  outline: none;
  box-shadow: none;
}

.Scraping-Bottom-Template {
  font-size: 20px;
  font-weight: 600;
  font-family: "Outfit", sans-serif;
}
.Scralink-Bottom-Template {
  color: white;
  padding: 19% 23% 19% 23%;
  border-radius: 28px;
  background-color: black !important;
  font-size: 20px;
  cursor: pointer;
}

.Shopping-Collage-Subcontent.error-container {
  border-color: red;
}

.Shopping-Collage-Header {
  font-size: 1.5rem;
  /* margin-bottom: 20px; */
}

.Shopping-Collage-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 25px;
  padding: 2% 2% 2% 2%;
}

/* .Shopping-Collage-Subcontent {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 4px;
} */

.Shopping-Collage-Subcontent {
  display: flex;
  border: 1px solid #d9d9d9;
  padding: 10px;
  border-radius: 4px;
  flex-direction: column;
  align-items: flex-start;
}
.Shopping-Collage-Subcontent .inSideLink {
  display: flex;
  width: 100%;
  border:1px solid #00000052;
  border-radius: 8px;
  padding:0 5px;
}

.images-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 images per row */
  gap: 10px; /* space between the images */
}

.Shopping-Collage-Subcontent.error-container {
  border-color: red;
}

.Scrapig-Linkoutlined {
  margin-right: 10px;
}

.Shopping-Collage-Input {
  flex: 1;
}

.validation-error {
  color: red;
  margin-top: 20px;
  font-size: 1rem;
}

.Template-Options {
  display: flex;
  gap: 20px;
}

/* .Template-Option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
} */

.Shopping-Collage-Bottom-button {
  display: flex;
  justify-content: end;
  padding: 0% 4% 0% 0%;
}

.Shopping-Collage-Bottom-button button {
  background-color: #d9d9d9;
}

.Collagelist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allows images to wrap if they exceed the container's width */
  gap: 10px; /* Adjust spacing between images */
  justify-content: space-between; /* Evenly distribute images across the row */
  margin-bottom: 20px;

  padding: 14px 0 0;
}

.collage-image {
  width: 48%;
  height: auto;
  margin-bottom: 10px;
}
/* .onlineProduct {
  display: flex;
  gap: 1rem;
  padding: 0% 4% 2% 4%;
} */

.onlineProduct {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 1rem; /* Space between items */
  padding: 0% 4% 2% 4%;
  background-color: #fff;
}
.onlineProduct img {
  width: 100%;
}
.onlineProductWardrobe img {
  height: auto;
}
.shoppongCollageContainer {
  /* padding: 0% 4% 2% 4%; */
}

.sideheadings {
  font-size: 19px;
}

.waddropselectfarme .Scralink-Bottom-Template p {
  margin-bottom: 0;
}

.Shopping-Collage-Header .Wardrobesubtabs {
  flex: 1 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Shopping-Collage-Products-Grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 16px; /* Space between items */
}
.product-item .selected {
  box-shadow: 1px 1px 3px 4px black;
}

.Shopping-Collage-Submit {
  display: flex;
  justify-content: flex-end;
  padding: 0% 1% 1% 2%;
  gap: 8%;
}

.product-item.selected {
  border: 2px solid green;
  border-radius: 8px;
}

.underline-dotted {
  width: 100%; /* Full width underline */
  border-bottom: 1px dotted black; /* Dotted underline */
  margin-top: 10px; /* Space between button and underline */
  margin-bottom: 25px;
  display: flex;
}
