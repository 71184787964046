.CollageComponentMain {
  box-shadow: 0px 0px 8px 0px #0000001a;
  padding-bottom: 10px;

  border-radius: 8px;
  overflow: hidden;
  margin: 2% 0;
  width: 100%;
}
.CollageComponentHeding {
  display: flex;
  justify-content: space-between;

  margin-bottom: 2.5%;
  padding: 0 2%;
  background: #c1b2c1;
}
.CollageComponentHeding h1 {
  margin: 0;
  padding: 5px;
}
.CollageComponentHeding .icon {
  font-size: 24px;
  cursor: pointer;
}
.CollageComponentHeding .icon:hover {
  font-size: 26px;
}
.CollageComponentimg {
  display: flex;
  margin: 11px 11px;
  align-items: center;
  justify-content: center;
}
