.CollageShoppingComponent .img {
  width: 8rem;
}

@media only screen and (max-width: 768px) {
  .CollageShoppingComponent .img {
    width: 3rem;
  }
}

.CollageShoppingComponent {
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px #9f9797;
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.CollageShoppingComponent .brand {
  font-size: 15px;
  font-weight: 600;
}
.CollageShoppingComponent .price {
  font-size: 17px;
  font-weight: 600;
  margin: 0;
}
.CollageShoppingComponent .name {
  font-size: 15px;
  font-weight: 400;
}
