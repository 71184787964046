@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.home-container {
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
}

.heading-section {
  display: flex;
  justify-content: space-between;
  gap: 2%;
  width: 100%;
}

.heading-container {
  flex: 1; /* Allow containers to grow equally */
  display: flex;
  background: white;
  border-radius: 14px;

  flex-direction: column;
}

.heading-container .heading {
  text-align: center;
  font-size: 1.1rem;

  margin: 0;
  background: #31363f;
  padding: 13px 0;
  font-family: Poppins;
  font-weight: 600;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
}

.body-section {
  /* Styles for the body section */
  margin-top: 1rem;
}

.Home-Container-Header{
  font-size: 22px;
  font-weight: 600;
  padding: 0% 0% 0% 1%;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .heading-section {
    flex-direction: column;
    gap: 2%; /* Reduced gap for medium screens */
  }

  .heading-container {
    margin-bottom: 1rem; /* Space between heading containers in column view */
  }
}

@media (max-width: 768px) {
  .home-container {
    padding: 0.5rem;
  }

  .heading {
    font-size: 1.2rem; /* Smaller font size for smaller screens */
  }

  .body-section {
    /* Adjust body section styling for smaller screens if needed */
  }
}
