@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.login-page-container {
  display: flex;
  height: 100vh;
  /* font-family:Outfit; */
  font-size: 35px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  background: #FBF1E9;

  /* font-weight: 700; */
  font-size: 20px;
  font-style: normal;
}

.carousel-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f0f0f0; */
}

.form-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  /* background-color: #ffffff; */
}

.login-form-container {
  width: 100%;
  max-width: 300px;
  /* border: 1px solid; */
  padding: 10% 9%;
  border-top-left-radius:50px ;
  border-bottom-left-radius:50px ;
  background: white;

}

.login-heading {
  text-align: center;
  margin-bottom: 15%;
  font-weight: 500;
  line-height: 35px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  color: #3c4768;
  margin-bottom: 2%;
}

.form-group input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #000000;
}

.login-button {
  width: 100%;
  padding: 10px;
  /* background-color: #007bff; */
  background: #d9d9d9;
  border-radius: 8px;
  color: black;
  border: none;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
  color: white;
}

.forgot-password {
  text-align: right;
  margin-bottom: 1px;
}

.forgot-password a {
  color: #3c4768;
  text-decoration: none;
  font-size: 15px;
}

@media (max-width: 768px) {
  /* .login-page-container {
    flex-direction: column;
  } */

  .carousel-side {
    height: 90vh;
  }

  .form-side {
    height: 90vh;
  }
}

.password-input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #555;
}

.password-toggle-icon:hover {
  color: #007bff;
}

.forgot-password {
  text-align: right;
  margin-bottom: 20px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}
