/* Parent container for the accessories component */
.accessories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 20px; */
  width: 81%;
  font-family: Arial, sans-serif;
}

/* Button styles for selecting user accessories */
.select-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.select-btn:hover {
  background-color: #0056b3;
}

/* Category tabs styles */
.category-tabs,
.subcategory-tabs {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.category-tab,
.subcategory-tab {
  padding: 10px 15px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.category-tab.active,
.subcategory-tab.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Subcategory images container */
.subcategory-images {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(4, 1fr); /* 4 images per row */
  gap: 20px;
  margin-bottom: 20px;
}

/* Individual subcategory items (images) */
.subcategory-item {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.subcategory-item:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Image styling with 10px border */
.subcategory-item img {
  width: 100%;
  /* border: 1px solid #ccc;
  border-radius: 5px; */
  padding: 5px;

}

/* Hide text under images */
.subcategory-item p {
  display: none;
}

/* Submit button container */
.submit-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.submit-btn {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #218838;
}

/* Loading spinner styles */
.loading-spinner,
.image-loading-spinner {
  font-size: 16px;
  color: #007bff;
  margin-top: 20px;
}

.scroll {
  overflow-x: auto;
  width: 100%;
  /* overflow: scroll; */
  display: flex;
  overflow-y: auto;
}

/* .scroll::-webkit-scrollbar{
width: 6px;
  } */



  
@media only screen and (max-width: 768px) {
  .Scrapiinglinkdataimage{
      width: 4rem !important;
  }

  .subcategory-images {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    overflow-y: auto;
    overflow: scroll;
}
  
}


@media (min-width: 1025px) { 

  .Scrapiinglinkdataimage{
    width: 4rem !important;
}

/* .subcategory-images {
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  margin-bottom: 20px;
  overflow: scroll !important;
} */

}
 
