.drop-area {
    position: absolute;
    transition: transform 0.3s ease-in-out;
  }

  .collagecreate .ant-card-body{
    height: 93%;
  }
  /* For tablet view */
  @media (max-width: 1024px) {
    .collagecreate .ant-card-body {
        height: 86%; 
    }
    .drop-area {
      position: relative;
      width: 100%;
      height: auto;
      top: auto;
      left: auto;
      margin-bottom: 10px; /* Add spacing between images */
    }
  }
  
  /* For mobile view */
  @media (max-width: 768px) {
    .drop-area {
      position: relative;
      width: 100%;
      height: auto;
      top: auto;
      left: auto;
      margin-bottom: 10px;
    }
    .collagecreate .ant-card-body {
        height: 76%; 
    }
  }
  