.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  text-align: center;
  height: 50%;
}
.popup-container .closebutton {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  position: relative;
}
.popup-container .closebutton .buttondiv {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.popup-container button {
  /* margin-top: 20px;
  padding: 2px 9px;

  color: black;
  font-size: 20px;

  height: auto;
  border-radius: 5px;
  cursor: pointer; */

  margin-top: 20px;
    padding: 4px 9px;
    color: black;
    font-size: 16px;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
}
.popup-container ul {
  padding: 0;
}

.popup-container li {
  cursor: pointer;
  margin: 10px 11px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  transition: background-color 0.3s;
  text-decoration: none;
  list-style-type: none;
}

.popup-container li:hover {
  background-color: #e0e0e0;
}
.extrabutton {
  border: 1px solid black;
  padding: 7px 19px;
  font-size: 14px;
}

.popup-container {
  position: relative;
  width: 400px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.close-button:hover {
  color: red;
}

.confirmation-popup {
  text-align: center;
}

.confirmation-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
