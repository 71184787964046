.changepassword {
  width: 100%;
  /* max-width: 600px; */
  margin: 0 auto;
  /* padding: 20px; */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
 
.changepassword .heading {
  /* text-align: center; */
  margin-bottom: 20px;
  font-size: 24px;
}
 
/* Flex container for the form */
.changepassword .change-password-form {
  /* display: flex;
    flex-wrap: wrap; */
 
  padding: 20px 39px;
}
 
/* Form items for layout within parent class */
.changepassword .ant-form-item {
  width: 100%; /* Default to full width */
}
 
/* Responsive layout for two fields per row */
@media (min-width: 768px) {
  .changepassword .ant-form-item {
    width: calc(50% - 16px); /* Two fields per row */
    margin-right: 16px;
  }
 
  .changepassword .ant-form-item:last-child {
    margin-right: 0; /* No margin for the last item in the row */
  }
}
 
/* Error message styling */
.changepassword .message.error {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}
 
/* Input field styling */
.changepassword .input-field {
  width: 100%;
  padding: 10px;
}
 
/* Error class for input fields */
.changepassword .input-field.error {
  border-color: red !important;
}
 
/* Button styling */
.changepassword .ant-btn-primary {
  width: 100%;
  height: 40px;
  font-size: 16px;
}