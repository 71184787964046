.Carousel-Main-Div{
  display: flex;
  justify-content: flex-end;
  width: 57rem !important;
}

.carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 48rem !important;
  overflow: hidden;
}

.custom-carousel .carousel-item {
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: relative;
  display: flex;
  justify-content: center;
  width: 80% !important;
}
.Carousel-Main-Div .custom-carousel .slick-slide{
width: 260px !important;
}

.custom-carousel{
  width: 55rem !important;
}

.carousel-item.center-slide {
  transform: scale(1.2); /* Make the center slide bigger */
  z-index: 2;
}

.carousel-item.side-slide {
  transform: scale(0.8); /* Make side slides smaller */
  opacity: 0.8;
  z-index: 1;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-content {
  /* max-width: 100%; */
  width: 76%;
  height: auto;
}


@media screen and (max-width: 768px) {
  .carousel-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 400px;
    width: 43rem !important;
    overflow: hidden;
    padding-left: 14%;
  }

  .Carousel-Main-Div {
    display: flex !important;
    justify-content: flex-end !important;
    width: 27rem !important;
  }

  .Carousel-Main-Div .custom-carousel .slick-slide{
    width: 260px !important;
    }
    
  

  .custom-carousel .carousel-item {
    width: 60% !important;
  }

  .carousel-content {
    width: 80% !important;
    height: auto !important;
  }
}


/* @media screen and (max-width: 1052px) { */
@media screen and (min-width: 1024px) and (max-width: 1264px) {
  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 30rem !important;
    overflow: hidden;
    padding-left: 37%;
  }

  .Carousel-Main-Div {
    display: flex !important;
    justify-content: flex-end !important;
    width: 42rem !important;
    /* padding: 1% 5% 1% 9% !important; */
    /* margin-left: 8%; */
  }

  .Carousel-Main-Div .custom-carousel .slick-slide{
    width: 250px !important;
    }


  /* .carousel-content {
    width: 80% !important;
    height: auto !important;
  } */
}
