.Chat-header-img {
    border-radius: 50%;
    width: 44px;
    height: 44px;
    margin-right: 15px;
  }


  .chat-container {
    /* max-height: 73vh; */
    overflow-y: auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }
  .chat-container::-webkit-scrollbar,.ant-drawer-body::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Optional: change background to match your design */
}

.chat-container ,.ant-drawer-body{
    scrollbar-width: none; /* Firefox */
}

.chat-container {
    -ms-overflow-style: none; /* IE and Edge */
}
  
  .my-message {
    align-self: flex-end;
    text-align: right;
    background-color: #f0f0f0;
    /* padding: 10px; */
    margin: 5px 0;
    border-radius: 10px;
    max-width: 80%;
    padding: 0% 2% 0% 3%;
  }
  
  .their-message {
    align-self: flex-start;
    text-align: left;
    background-color: #e6f7ff;
    padding: 0% 2% 0% 3%;
    margin: 5px 0;
    border-radius: 10px;
    max-width: 80%;
  }
  
  .my-message img, .their-message img {
    max-width: 100%;
    /* height: auto; */
    display: block;
    margin-top: 10px;
  }

  .Chat-Ptag{
    display: flex;
    align-items: flex-end;
  }

  .Chat-Ptag span{
    margin: 5px;
  }

  .Chat-Bottom-content{
    display: flex;
    align-items: flex-end;
    gap: 3%;
    /* position: fixed; */
    justify-content: space-between;
  }

.Chat-Bottom-content input{
    width: 77%;
}