.userProfileMain {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
}
.userProfileMain .top {
  /* display: flex;
  justify-content: space-between;
  background: #31363f;
  padding: 0% 2%;
  align-items: center; */
  display: flex;
  justify-content: space-between;
  background: #31363f;
  padding: 0% 0%;
  align-items: center;
  padding-right: 2%;
}
.userProfileMain .top button {
  border-radius: 8px;
  color: #31363f;
  cursor: pointer;
  border: none;
  font-weight: 400;
  padding: 5px 15px;
}
.userProfileMain .heading {
  /* background: #31363f;
  color: white;
  padding: 1% 2%;
  margin: 0; */
  background: #31363f;
  color: white;
  padding: 1% 2%;
  margin: 0;
  font-weight: 600;
  border-radius: 10px;
  font-size: 21px;
}
.userProfielBody {
  /* margin: 1% 2%;
 background: #FBF1E9; */
  margin: 2% 2%;
  background: #fbf1e9;
  padding: 0 0 2%;
  border-radius: 8px;
}

.userProfileBody2 {
  padding: 0 0% 2%;
  margin: 2% 2%;
}

.user-profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* border: 1px solid #ddd; */
  padding: 2%;
  border-radius: 5px;
  /* background-color: #f9f9f9; */
}

.profile-info {
  display: flex;
  width: 82%;
  align-items: center;
  gap: 9%;
}

.profile-info p {
  font-size: 18px;
  font-weight: 500;
}

.profile-img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-right: 15px;
}

.profile-details {
  display: flex;
  flex-direction: column;
}

.profile-name,
.profile-dob,
.profile-height {
  margin: 0;
  font-size: 14px;
}

.profile-button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.profile-button:hover {
  background-color: #0056b3;
}
.UserTabs {
  display: flex;
  justify-content: space-between;
  margin: 0 1%;
  overflow-y: auto;
  /* padding-bottom: 2%; */
  gap: 10px;
  padding: 2% 1%;
  /* scrollbar-width: 2px; */
  overflow-y: auto;
  overflow-x: auto;
}
.UserTabs .user-tabs-container {
  /* width: auto; */
  max-width: 15rem;
}
.boder{
  margin: 0;
  margin-bottom: 27px;
  color: white;
  background-color: white;
  border-color: white;
  height: 4px;
  border: none;
}
.button{
  padding: 8px 21px;
  border: none;
  color: #31363f;
  border: 1px solid;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  border-radius: 30px;
}
.button:hover{
  background-color: #31363f !important;
  color: white !important;
}

.backButton{

 padding: 8px 21px;
  border: 1px solid  white !important;
  /* color: #31363f; */
  border: 1px solid;
  color: white !important;
  background: transparent;
  /* border-radius: 4px; */
  cursor: pointer;
  border-radius: 30px !important;
}

.backButton:hover{
  color: #31363f !important;
  background-color: white;
}



@media (max-width: 1024px) and (min-width: 768px) {
  .profile-img {
    /* width: 136px !important; */
    height: 83px !important;
  }
 
  .profile-name,
  .profile-dob,
  .profile-height {
    font-size: 12px;
  }
 
  .button {
    font-size: 14px;
    padding: 8px;
  }
 
 
 
  .boder {
    width: 90%;
  }
}
 
/* Responsive styles for smaller tablets */
@media (max-width: 768px) {
 
  .profile-info {
    display: flex;
    width: 80%;
    align-items: center;
    gap: 5%;
  }
  .profile-img {
    width: 70px;
    height: 50px;
    margin-right: 0;
  }
 
  .profile-info p{
    font-size: 14px !important;
  }

  .Profile-subheader{
    font-size: 12px !important;

  }
 
  .profile-name,
  .profile-dob,
  .profile-height {
    font-size: 14px;
  }
 
  .button {
    font-size: 12px;
    padding: 6px;
  }
 
  .user-tabs-container .user-tabs-text {
    font-size: 15px !important;
  }
 
  .user-tabs-container .img img {
    width: 38px !important;
  }
 
  .userProfileMain .heading {
    font-size: 15px !important;
    background: #31363f;
    color: white;
    padding: 2% 4% !important;
    margin: 0;
    font-weight: 600;
  }
 
 
 
  .boder {
    width: 90%;
  }
}




.Profile-header{
  margin-right: 10px;
  font-size: 17px;
}
.Profile-subheader{
  font-size: 16px;
  color:#6d7076
}