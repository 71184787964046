.user-tabs-container {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  padding: 10px;
  cursor: pointer;
  gap: 7px;

  border-radius: 10px;
}
.user-tabs-container .img {
  display: flex;
  width: 11rem;
  gap: 10px;
  align-items: center;
}
.user-tabs-container .img img {
  border-radius: 50%;
  width: 42px;
  background: #31363f;
  padding: 6%;
}

.user-tabs-container .user-tabs-text {
  font-size: 18px;
  font-weight: 500;
}
