.Manageuser-Content{
  padding: 2% 5% 2% 5%;
}
.Manageuser-Form-Input{
  border: 1.3px solid #ABABAB;
  padding: 1.5%;
}

.Manageuser-FormItem .ant-select-selector {

  height: 38px !important;
  border-radius: 4px !important;
  border: 1.3px solid #ABABAB !important;
}
.Profile-Header-Logo{
  /* justify-content: space-around; */
  /* width: 80%; */
  gap: 4%;
  padding: 0% 0% 4% 0%;
}
/* .Profile-Header-Buttons button{
  background-color: #31363F;
  color: white;


} */
.Manageuser-FormItem .ant-form-item-label > label {
  font-size: 17px; 
  font-weight: 400;
  font-family: 'Outfit',sans-serif;
}


.Manageuser-FormItem button {
  background-color: #31363F;
  color: white;
}






.Manageuser-Header-Adduser{
padding: 1%;
display: flex;
justify-content: flex-end;
}

/* .Manageuser-Header-Adduser button{
background-color:#31363F;
font-size: 17px;
color: white;
padding: 1.5%;

} */


.Manageuser-Table{
padding: 0% 1% 1% 1%;
}

.customTableHeader .ant-table-thead > tr > th {
  background-color: #FBF1E9; 
  color: #000; 
  font-size: 16px;
  font-family: 'Outfit',sans-serif;
  
}

.customTableHeader .ant-table-tbody > tr {
  font-size: 16px;
  font-family: 'Outfit',sans-serif;
}

.customTableHeader .ant-table-tbody > tr:hover {
  background-color: #f5f5f5; 
}

.customTableHeader .ant-table-tbody .ant-table-cell {
  transition: background-color 0.3s;
}

.customTableHeader .ant-table-tbody .ant-btn:hover {
  background-color: #FBF1E9 !important; 
  border: #FBF1E9;
}


.Manageuser-Add-Edit-Modal .ant-modal-content {
  padding: 0%;
}

.Manageuser-Add-Edit-Modal  .ant-modal-title {
  background-color: antiquewhite;
  padding: 3%;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.Manageuser-Add-Edit-Modal .ant-modal-footer {
  padding: 3%;
}

@media (max-width: 768px) {
  .Manageuser-Header-Adduser{
    padding: 2% 2% 0% 0%;
  }
  .Manageuser-Table{
    padding: 2%;
  }
}


.adduserbutton{
  display: flex;
  justify-content: center;
  align-items: center;

}